body {
    margin: 0;
    padding: 0;
}
  
app-root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.app-background {
    background-color: rgb(235, 235, 235);
}